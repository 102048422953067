.main-menu {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: -webkit-fill-available;
  height: 100%;
}
.container {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  margin: 20px;
}

.titleContainer {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bolder;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.titleButton {
  background-color: var(--main-color) !important;
  padding: 0px 20px;
  height: 40px;
}

.titleButton:hover {
  background-color: var(--button-click) !important;
}

.gridContainer {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  min-height: 0;
}

.secondColumn {
  display: grid;
  /* grid-template-rows: repeat(2, 1fr); */
  grid-template-rows: 1.5fr 2fr;
  grid-row-gap: 20px;
  grid-template-areas: "1" "2";
  min-height: 0;
}

.text {
  font-size: 13px;
  line-height: 20px;
  min-height: 0;
}
[contenteditable] {
  outline: 0.5px solid grey;
  padding: 5px;
  border-radius: 5px;
}

.box {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  font-size: 40px;
  font-family: sans-serif;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}

.messagesContainer {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
}

.generatedOutputContainer {
  font-size: 13px;
  line-height: 20px;
  height: 100%;
  white-space: pre-wrap;
  overflow-y: auto;
  min-height: 0;
}

.main-menu {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  width: inherit;
  /* height: inherit; */
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid black;
  padding-top: 10px;
  gap: 16px;
  font-size: 15px;
}

.buttonsContainer.multipleChild {
  justify-content: space-between;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: var(--main-color);
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.leftButtonsContainer {
  display: flex;
  gap: 16px;
}
