.container {
  height: 100%;
  top: 0;
  min-width: 325px;
  background-color: var(--background-color);
  border-color: var(--menu-border-color);
  border-right-style: solid;
  border-right-width: 1px;
}

.hideMenuButton {
  height: 30px;
  left: 444px;
  position: fixed;
  top: 495px;
  width: 30px;
  transform: rotate(180deg);
  background-color: white;
  border-radius: 50%;
  border: 1px black solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showMenuButton {
  height: 30px;
  left: 60px;
  position: fixed;
  top: 495px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
  border: 1px black solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hideMenuIcon {
  height: 30px;
  width: 30px;
}

.titleContainer {
  height: 60px;
  left: 0;
  /* position: absolute; */
  top: 0;
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}

.searchBarContainer {
  left: 0;
  top: 62px;
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
}

.searchBox {
  align-items: center;
  display: flex;
  gap: 10px;
  padding: 0px 20px;
  width: -webkit-fill-available;
  margin: auto;
}

.searchIcon {
  height: 24px;
  width: 24px;
  padding: 0px 8px;
  position: fixed;
}

.searchBoxInput {
  /* color: #d9d9d9; */
  /* font-family: "Inter-Regular", Helvetica; */
  font-size: 15px;
  font-weight: 400;
  height: 37px;
  letter-spacing: 0;
  line-height: 16.5px;
  margin-top: -1px;
  position: relative;
  width: inherit;
  padding-left: 40px;
}

.optionsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.button {
  display: flex;
  border-width: 0;
  padding: 12px;
  padding-left: 20px;
}

.eJButton {
  background-color: var(--background-color);
  border-color: var(--main-color);
  border-width: 1px;
  border-radius: 5px;
  color: var(--main-color);
  opacity: 1;
}

.eJButton:hover {
  opacity: 0.75;
}

.datePicker {
  border-width: 0;
  border-color: var(--menu-border-color);
  padding: 12px;
  padding-left: 20px;
  background-color: var(--background-color);
}

.textContainer {
  align-items: center;
  display: flex;
  gap: 10px;
  padding: 0px 20px;
  position: relative;
  width: -webkit-fill-available;
}

.text {
  /* color: var(--main-color); */
  font-family: var(--ticket-menu-font-family);
  font-size: var(--ticket-menu-font-size);
  font-style: var(--ticket-menu-font-style);
  font-weight: var(--ticket-menu-font-weight);
  /* height: 44px; */
  letter-spacing: var(--ticket-menu-letter-spacing);
  line-height: var(--ticket-menu-line-height);
  margin: auto;
  position: relative;
  width: inherit;
}

.separator {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 30px;
  position: relative;
  width: inherit;
}

.rectangle {
  align-self: stretch;
  background-color: #d9d9d9;
  height: 2px;
  min-width: inherit;
  position: relative;
}

.section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  /* gap: 14px; */
  padding: 0px 10px;
  position: relative;
  width: 100%;
}
