.loader {
  margin: auto;
  border: 20px solid #eaf0f6;
  border-radius: 50%;
  border-top: 20px solid var(--main-color);
  width: 200px;
  height: 200px;
  animation: spinner 4s linear infinite;
}

.backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgb(223, 223, 223, 0.5);
  z-index: 20;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
