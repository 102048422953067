.container {
  min-width: 400px;
  max-width: 400px;
  background-color: var(--background-color);
  border-color: var(--menu-border-color);
  border-left-style: solid;
  border-left-width: 1px;
  padding: 25px;
  overflow-y: auto;
  display: grid;
  grid-template-rows: repeat(2);
}
