.image {
  margin-left: 15px;
}

.image:hover {
  cursor: pointer;
}

.container {
  display: flex;
  align-items: center;
}
