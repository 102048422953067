.buttonsContainer {
  display: flex;
  gap: 10px;
  margin: 10px 0px;
  justify-content: flex-end;
}

.containedButton {
  background-color: var(--main-color) !important;
}

.outlinedButton {
  border-color: var(--main-color) !important;
  color: var(--main-color) !important;
}
