.component {
  background-color: #d9d9d9;
  border-radius: 20px;
  height: 22px;
  position: relative;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.element {
  color: #000000;
  font-family: var(--ticket-menu-font-family);
  font-size: var(--ticket-menu-font-size);
  font-style: var(--ticket-menu-font-style);
  font-weight: var(--ticket-menu-font-weight);
  height: 19px;
  /* left: 7px; */
  /* letter-spacing: var(--ticket-menu-letter-spacing);
  line-height: var(--ticket-menu-line-height); */
  position: absolute;
  text-align: center;
  /* top: 0; */
  white-space: nowrap;
  width: 25px;
}
