.parametersContainer {
  gap: 50px;
}

.titleText {
  font-size: 18px;
  font-weight: 500;
}

.paramContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  font-size: 14px;
  margin: 5px 0px;
  height: 100%;
}

.paramContainer > textarea {
  width: 100%;
  font: inherit;
  letter-spacing: inherit;
  resize: vertical;
  min-height: 300px;
}

.upperButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid grey;
  margin-top: 10px;
}

.lowerButtonsContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  gap: 20px;
  font-size: 12px;
  line-height: 20px;
}

.inputBox[contenteditable] {
  outline: 0px solid transparent;
}
.inputBox {
  margin-left: 1rem;
  flex: 1;
  border-bottom: 1px solid rgb(130, 130, 130) !important;
  font: inherit;
  letter-spacing: inherit;
  /* color: var(--main-color); */
  padding: 5px 0px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
}

.inputBox:focus-visible {
  outline: 0px !important;
  border-bottom: 1.5px solid var(--main-color) !important;
}

.inputBox:focus-visible::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--main-color);
  opacity: 1; /* Firefox */
}

.inputBox:focus-visible:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--main-color);
}

input:focus-visible::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--main-color);
}
