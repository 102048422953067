.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  position: relative;
  width: -webkit-fill-available;
  cursor: pointer;
}

.textContainer {
  color: #000000;
  font-family: var(--ticket-menu-font-family);
  font-size: var(--ticket-menu-font-size);
  font-style: var(--ticket-menu-font-style);
  font-weight: var(--ticket-menu-font-weight);
  letter-spacing: var(--ticket-menu-letter-spacing);
  line-height: var(--ticket-menu-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.badge {
  min-width: 40px !important;
  width: unset !important;
}

.active {
  background-color: white;
}
