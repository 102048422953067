.container {
  border: 1px solid black;
  display: flex;
  height: -webkit-fill-available;
  border-radius: 3px;
}

.messagesContainer {
  display: flex;
  flex-direction: column;
  flex: 0.8;
}
